<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="aboutheader"/>
    <div class="container-fluid quotecontainer">
      <div class="container quotetext"> 
          <span class="largetext">"Never Cut What Can Be Untied"</span> is the inspiration for Jacinta's work.
      </div>
    </div><!--container-fluid-->

<div class="container-fluid textured" style="padding-top:50px; padding-bottom:50px;">
  <div class="container"  style="text-align:left;">     
    <div class="bubbleimage2">
      <img style="max-width:170px;" 
        src="../assets/images/jacintaheadshot.png" 
        title="Jacinta Gallant Collaborative Lawyer and Mediator" 
        alt="Jacinta Gallant Training &amp; Presentations in Collaborative Law">
    </div>
    <h1>About Jacinta</h1>
    <p>Jacinta Gallant is a respected Canadian collaborative lawyer, conflict trainer, 
      mediator and innovator in her field. She is recognized internationally for her 
      insightful and experiential approach to teaching and managing conflict, and has 
      been welcomed as a trainer throughout Canada, the United States, Europe, Australia 
      and Southeast Asia.  </p>
    <a class="gotobutton" style="float:right;margin-left:15px;" href="https://www.innovateforlawyers.com/#podcast" target="_blank">
    Jacinta's Podcast: The Authentic Professional</a>
    <p>"Never cut what can be untied" is the inspiration for Jacinta's work. 
      Leaving behind many years as a successful litigation lawyer, Jacinta now works 
      exclusively as a settlement advocate - using her skill and wisdom to help people 
      untangle the knots of conflict while attending to relationships. </p>
  </div>
</div>

<div class="container-fluid maincontentcontainer">     
  <div class="container"  style="text-align:left;">
    <p>Jacinta is the principal of <a href="http://www.waterstonelawpei.ca" target="_blank">Waterstone Law Group</a>,
    a firm specializing in innovative legal services for families, and is the founder and 
    president of <a href="https://www.innovateforlawyers.com/" target="_blank">Innovation 
    for Lawyers Inc.</a>. She is known for her keen intelligence, engaging personality, 
    and practical, non-judgmental approach to conflict. 
    Her trainings are interactive, structured and insightful - with the goal of helping 
    professionals be more authentic and natural in their work. </p>
    <p>Jacinta is a former member of the Board of Directors of the International Academy 
      of Collaborative Professionals (IACP) and a Trainer with the IACP Training Faculty 
      tasked with  delivering Interdisciplinary Collaborative Practice training throughout 
      the world. She is the founder of collaborative practice on Prince Edward Island and is 
      a leader in the national and international collaborative practice community.</p>
    <p>Her formal education came from the University of Prince Edward Island (Bachelor of Arts), 
      Dalhousie University and University of Victoria Law Schools (Juris Doctor). 
    She has extensive academic and professional conflict resolution training and is a student 
    and teacher of the <a href="http://insightapproach.ca/index.php" target="_blank">Insight Approach to Conflict</a>. </p>
    <p>Jacinta has two young adult children and lives with her husband and 2 dogs in Hampton, 
      Prince Edward Island. She travels extensively to deliver conflict training and continues 
      to learn from the people around her. Every summer, Jacinta hosts Summer Sessions for Conflict 
      professionals, bringing people to beautiful Prince Edward Island for deep and challenging 
      professional development in a world class vacation destination. </p>
    <p>With a strong yoga practice, a deeply rooted family and a love of nature, Jacinta is grounded, 
      adventurous and generous. </p>
    <h2><br/><br/>What people say about Jacinta’s training:<br/><br/></h2>

<div class="row">
  <div class="col-md-4">
        <div class="testimonialfont">
        <br/>
          <img src="../assets/images/leftquoteblue.png"> 
          I came away from Jacinta Gallant's training energized, 
          enthusiastic about having deepened my skill set, and deeply 
          impressed with her authentic, warm, informed and engaging presence.  
          I highly recommend this training.
          <img src="../assets/images/rightquoteblue.png">
          <div class="pull-right" style="text-align:right;">
            <br/><i><small>Deepening Without Drowning: Accessing Hope, Managing Threats & Breathing Fresh Air 2019</small></i></div>
        </div>
  </div>
  <div class="col-md-4">
        <div class="testimonialfont2"><br/><img src="../assets/images/leftquote.png"> 
          Definitely make the time to attend Jacinta’s workshops. 
          She is a charismatic and insightful speaker.  
          Learning from her experience is completely essential if you practice collaborative law. 
          <img src="../assets/images/rightquote.png">  
          <div class="pull-right" style="text-align:right;"><br/>
            <small><i>Shifting from Judgment to Curiosity</i><br/> Perth, Scotland 2017</small>
          </div>
        </div>
        <br><br><br><br>
  </div>
  <div class="col-md-4">
          <div class="testimonialfont">
          <br/>
          <img src="../assets/images/leftquoteblue.png"> 
          Your presentation was really wonderful.  
          It shed a higher level light to our current practice. 
          So many times we attend a presentation and walk away thinking, 
          “o.k., I’m doing most of that.  I should or may be able to tweak this or that.”  
          In this case, I and many of my peers were really moved by your presentation!
          <img src="../assets/images/rightquoteblue.png">
          <span class="pull-right" style="text-align:right;"><small><i><br/>
          Shifting from Judgment to Curiosity </i><br/>Collaborative Professionals of Washington USA   </small></span>
        </div>
        <br><br><br><br>
  </div>

  <div class="col-md-4">
        <div class="testimonialfont2"><br/><img src="../assets/images/leftquote.png"> 
        Jacinta’s teaching on interests was an inspiration. 
        She is warm and hilarious and (then you find) incredibly experienced, principled and wise. 
        There is joyous de-bunking of some of that slightly self-righteous or regimented practice 
        that it is so easy for us to acquire like calcium deposits as we do this work and instead, 
        during her trainings, we get a clean sense of the professional we wanted to be when we started out.           
        <img src="../assets/images/rightquote.png">
        <div class="pull-right" style="text-align:right;">
          <small><i><br/>Keeping Interests on the Table</i><br/> London, England 2016</small></div>

          </div>
          <br><br><br><br>
  </div>
  <div class="col-md-4">
          <div class="testimonialfont">
          <br/>
          <img src="../assets/images/leftquoteblue.png"> 
          Out of all of the presentations that I attended at the IACP Forum, 
          yours was by far my favorite.  I was so excited to share with my 
          fellow collaborative practitioners the concept of using the interest 
          agenda to create solutions to meet our clients’ interests.  
          I believe that this will also help “balance” certain power struggles 
          we encounter during the process by ensuring that even the less vocal 
          party’s interests are put forth and considered equally as those put 
          forth by the more vocal individual.  It forces the professionals to 
          work with clients to identify what their real interests are, going much 
          deeper than what the clients may initially put forth as more position-oriented needs.
          <img src="../assets/images/rightquoteblue.png">
          <span class="pull-right" style="text-align:right;">
            <small><i><br/>Keeping Interests on the Table</i> <br/>IACP Forum 2015</small></span>
          <br><br><br><br>
        </div>
  </div>
  <div class="col-md-4">
        <div class="testimonialfont2"><br/><img src="../assets/images/leftquote.png"> 
        Jacinta, I think I can speak for everyone when I say how much we enjoyed 
        being with you last week! It was an amazing and inspirational 2 days. 
        We learned so much and I can’t wait to get to practice this. It was also 
        a lot of fun- your spirit and energy are contagious!      
        <img src="../assets/images/rightquote.png">
        
          <div class="pull-right" style="text-align:right;">
            <small><br/><br/><i>Advanced Skills Workshop,</i> <br/>Pittsburgh, Pennsylvania 2017</small></div>
          
          </div>
          <br><br><br><br>
  </div>

</div>
      <br/>
      <div style="text-align:center;"><router-link to="TrainingPresentations" class="gotobutton">For Training Inquiries Click Here!</router-link></div>
      
    </div> <!-- /container -->
</div>
    <Footer/>
  </div>
</template>

<script>
// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

export default{
  name: 'About Jacinta',
  components: {
    Header,
    Footer
  },
  setup() {

    const environment = process.env.VUE_APP_ENVIRONMENT

    return {
      environment
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
